// All sections called "three"

.three {

    &_home {
        position: relative;
        top: rem(-220);

        &_txt {
            padding: rem(100) rem(154) rem(400);
        
            @media (max-width: $tablet) {
                padding: rem(96) rem(30) rem(200);
            }
        
            @media (max-width: $small-mobile) {
                padding: rem(96) rem(35) rem(200);
            }

            h2, h3 {
                font-weight: 400;
                font-size: rem(25);
                line-height: rem(39.57);
                text-align: center;
                color: $acdf-blue;
                padding-bottom: rem(30);
        
                @media (max-width: $tablet) {
                    font-size: rem(22);
                    line-height: rem(30.57);
                }
            }

            p {
                font-weight: 400;
                font-size: rem(16);
                line-height: rem(25.33);
                text-align: center;
                color: $acdf-grey;
                margin-bottom: rem(0);
                margin-block-start: rem(0);
                margin-block-end: rem(0);
        
                @media (max-width: $tablet) {
                    font-size: rem(15);
                    line-height: rem(20.33);
                }
            }

            a {
                font-weight: 600;
                font-size: rem(20);
                line-height: rem(31.66);
                color: $acdf-grey;
                text-decoration: none;
                display: flex;
                justify-content: center;
                transition: 0.3s ease-out;
                text-align: center;
                max-width: none !important;
        
                @media (max-width: $tablet) {
                    font-size: rem(15);
                    line-height: rem(20.33);
                }

                &:hover {
                    transform: translateY(rem(-5));
                    color: $acdf-gold;
                }
            }
        }

        &_left {
            position: relative;
            left: 4%;
        }

        &_right {
            display: flex !important;
            flex-direction: column;
            justify-content: flex-end !important;
            align-items: center !important;
        }

        &_img {
            width: 100%;

            img {
                width: 100%;
            }

            &-2 {
                width: 100%;
        
                @media (max-width: $tablet) {
                    display: none;
                }
            }

            &-3 {
                display: flex;
                justify-content: center;
                position: relative;
                top: rem(-110);
                width: 100%;
        
                @media (max-width: $tablet) {
                    width: 50%;
                }

                img {
                    width: 100%;
                }
            }
            
            &-flower {
                position: absolute;
                right: 0;
                top: -3%;
                z-index: 1;
        
                @media (max-width: rem(1640)) {
                    display: none;
                }
            }
        }

        .col-sm-6 {
            display: flex;
            align-items: center;
            justify-content: center;
        
            @media (max-width: $tablet) {
                flex: 0 0 100%;
            }
        }

        .row {

            &:first-child {
        
                @media (max-width: $tablet) {
                    flex-direction: column-reverse;
                }
            }
        }

        .container, .row{
            max-width: 100%;
            margin: rem(0);
            padding: rem(0);
        }

        .col-sm-6 {
            margin: rem(0);
            padding: rem(0);
        }
    }

    &_villa {
        position: relative;

        &_txt {
            display: flex;
            justify-content: center;

            a {
                background-color: $acdf-gold;
                font-weight: 400;
                font-size: rem(20);
                line-height: rem(27);
                color: $acdf-low-white;
                display: flex;
                justify-content: center;
                padding: rem(10) rem(20);
                margin: rem(40) rem(110);
            }

            &-2 {
                background-color: $acdf-low-blue;
                padding: rem(42) rem(90) rem(10) rem(90);
                position: relative;
                top: 5%;
                left: 10%;
                z-index: 1;
            
                @media (max-width: $mobile) {
                    position: static;
                }
        
                @media (max-width: $small-mobile) {
                    padding: rem(42) rem(30) rem(10) rem(30);
                }

                h2 {
                    font-weight: 400;
                    font-size: rem(42);
                    line-height: rem(55.99);
                    color: $acdf-low-white;
        
                    @media (max-width: $mobile) {
                        font-size: rem(30);
                        line-height: rem(30.33);
                    }
                }

                ul {
                    padding-inline-start: rem(20);
                    
                    li {
                        font-weight: 400;
                        font-size: rem(15);
                        line-height: rem(25.5);
                        color: $acdf-low-white;
                        margin-bottom: 0;
                        margin-block-start: 0;
                        margin-block-end: 0;
                    }
                }
            }
        }

        &_img {
            display: flex;
            justify-content: flex-end;
            
            @media (max-width: $mobile) {
                justify-content: center;
            }

            img {
                width: 100%;
            
                @media (max-width: $mobile) {
                    width: 70%;
                }
            }
            
            &-flower {
                position: absolute;

                img {
                    position: relative;
                    width: 100%;
                    left: 166%;
                    top: rem(-404);
                    z-index: 2;
                }
            }

            &-divider {
                display: flex;
                justify-content: flex-start;
                position: relative;
                padding: rem(10) rem(0) rem(60) rem(0);
            }
        }

        &_right {
            position: relative;
            padding-right: rem(138) !important;
            
            @media (max-width: $mobile) {
                padding-right: rem(0) !important;
            }
        }

        &_left {
            position: relative;
            padding-left: rem(62) !important;
            
            @media (max-width: $mobile) {
                padding-left: rem(0) !important;
            }
        }

        .container, .row, .col-sm-6 {
            max-width: none;
            margin: 0;
            padding: 0;
        }
    }

    &_decouvrez-la-region {
        position: relative;
        top: rem(-100);

        &_img {

            img {
                width: 100%;
                max-width: 100%;
                object-fit: cover;
                height: rem(1200);
                padding-bottom: rem(40);
        
                @media (max-width: $tablet) {
                    height: rem(480);
                }
            }
        }

        .col-sm-6 {
            padding: rem(30) rem(40);
        }

        .container {
            max-width: 90%;
        }
    }
}