// All style for Google Maps responsive

.map-responsive {

    @media (max-width: $mobile) {

        iframe {
            height: rem(400);
            width: 100%;
        }
    }
}