// All sections called "modal"

.modal {

    &-header, &-body, &-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    &-dialog {
        max-width: 60%;
        margin: rem(130) auto;
        
        @media (max-width: $mobile) {
            max-width: 90%;
        }
    }

    &-content {
        background-color: $acdf-low-white;
    }

    &-header {
        border-bottom: rem(0);
        border-bottom-left-radius: rem(0);
        border-bottom-right-radius: rem(0);
    }
    
    &-body {
        padding: rem(0) rem(50) rem(50);
        height: 100%;
        background-color: $acdf-low-white;

        h2 {
            font-weight: 400;
            font-size: rem(40);
            line-height: rem(63.32);
            text-align: center;
            color: $acdf-blue;
            padding-bottom: rem(40);
        
            @media (max-width: $mobile) {
                font-size: rem(30);
            }
        }
        
        h3 {
            font-weight: 400;
            font-size: rem(20);
            line-height: rem(31.66);
            text-align: center;
            color: $acdf-gold;
        }

        p {
            font-weight: 400;
            font-size: rem(16);
            line-height: rem(25.33);
            text-align: center;
            color: $acdf-grey;
            margin-bottom: 0;
            margin-block-start: 0;
            margin-block-end: 0;
        
            @media (max-width: $mobile) {
                font-size: rem(13);
            }
        }
    }

    &-footer {
        flex-direction: row;
        padding: rem(10) rem(0);

        p {
            font-weight: 400;
            font-size: rem(12);
            line-height: rem(21.66);
            text-align: center;
            color: $acdf-gold;
        }
    }
}