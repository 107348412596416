.card-logement-container {
    display: flex;
    justify-content: space-around;
    align-items: center;

    @media(max-width: $tablet) {
        flex-direction: column;
    }

    .card-container {
        min-height: 460px;
        width: 30%;

        @media(max-width: $tablet) {
            width: 90%;
        }

        .card {
            position: relative;
            border-radius: 30px;
            overflow: hidden;
            margin-bottom: 15px;

            .price {
                position: absolute;
                top: 0;
                background-color: green;
                padding: 10px 20px;
                color: white;
            }

            img {
                width: 100%;
                height: 200px;
                object-fit: cover;
            }

            .card-body {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
            }
        }
    }
}