.search-home {
    position: relative;
    z-index: 2;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    padding: 0 20px;

    label {
        color: #fff;
    }
}