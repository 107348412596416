// All sections called "five"

.five {

    &_home {
        position: relative;
        top: rem(-416);

        &_title {

            h2 {
                font-weight: 400;
                font-size: rem(40);
                line-height: rem(63.32);
                text-align: center;
                padding-bottom: rem(30);
        
                @media (max-width: $tablet) {
                    font-size: rem(28);
                    line-height: rem(40.57);
                }
            }

            span {
                margin: rem(0) rem(-5);

                &.first-letter    { color: $first-letter-color; }    // <= "L" letter for Legnu & Resina page
                &.second-letter   { color: $second-letter-color; }   // <= "E" letter for Legnu & Resina page
                &.third-letter    { color: $third-letter-color; }    // <= "G" letter for Legnu & Resina page
                &.fourth-letter   { color: $fourth-letter-color; }   // <= "N" letter for Legnu & Resina page
                &.fifth-letter    { color: $fifth-letter-color; }    // <= "U" letter for Legnu & Resina page
                &.sixth-letter    { color: $sixth-letter-color;      // <= "&" letter for Legnu & Resina page
                                    margin: rem(0) rem(10); }
                &.seventh-letter  { color: $seventh-letter-color; }  // <= "R" letter for Legnu & Resina page
                &.eighth-letter   { color: $eighth-letter-color; }   // <= "E" letter for Legnu & Resina page
                &.ninth-letter    { color: $ninth-letter-color; }    // <= "S" letter for Legnu & Resina page
                &.tenth-letter    { color: $tenth-letter-color; }    // <= "I" letter for Legnu & Resina page
                &.eleventh-letter { color: $eleventh-letter-color; } // <= "N" letter for Legnu & Resina page
                &.twelfth-letter  { color: $twelfth-letter-color; }  // <= "A" letter for Legnu & Resina page
        
                @media (max-width: $tablet) {
                    margin: rem(0) rem(-3);
                }
            }
        }

        &_txt {
            padding: rem(60) rem(300);
        
            @media (max-width: $tablet) {
                padding: rem(60) rem(60);
            }

            p {
                font-weight: 400;
                font-size: rem(16);
                line-height: rem(25.33);
                text-align: center;
                color: $acdf-grey;
                margin-bottom: rem(0);
                margin-block-start: rem(0);
                margin-block-end: rem(0);
        
                @media (max-width: $tablet) {
                    font-size: rem(15);
                    line-height: rem(20.33);
                }
            }
        }

        &_img {
            display: flex;
            justify-content: flex-end;
            
            @media (max-width: $mobile) {
                justify-content: center;
                padding-bottom: rem(50);
            }

            img{
                width: 50%;
            }

            &-logo {
                display: flex;
                justify-content: center;
            }
        
            &-2 {
                display: flex;
                justify-content: center;
            
                @media (max-width: $mobile) {
                    padding-bottom: rem(50);
                }
        
                img{
                    width: 50%;
                }
            }
        
            &-3 {
                display: flex;
                justify-content: flex-start;
            
                @media (max-width: $mobile) {
                    justify-content: center;
                    padding-bottom: rem(50);
                }
        
                img{
                    width: 50%;
                }
            }
        
            &-4 {
                display: flex;
                justify-content: center;
                position: relative;
                top: rem(91);
        
                img {
                    width: 90%;
                }
            }
            
            &-flower {
                position: absolute;
                top: -9%;
                z-index: 1;
        
                @media (max-width: rem(1160)) {
                    display: none;
                }
            }
        }

        &_right {
            position: relative;
            left: -7%;
        }

        .col-sm-6 {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .container, .row, .col-sm-4 {
            max-width: none;
            margin: 0;
            padding: 0;
        }
    }
}