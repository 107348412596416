/*!
 * Bootstrap v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@import "./functions";
@import "./variables";
@import "./mixins";
@import "./root";
@import "./reboot";
@import "./type";
@import "./images";
@import "./code";
@import "./grid";
@import "./tables";
@import "./forms";
@import "./buttons";
@import "./transitions";
@import "./dropdown";
@import "./button-group";
@import "./input-group";
@import "./custom-forms";
@import "./nav";
@import "./navbar";
@import "./card";
@import "./breadcrumb";
@import "./pagination";
@import "./badge";
@import "./jumbotron";
@import "./alert";
@import "./progress";
@import "./media";
@import "./list-group";
@import "./close";
@import "./modal";
@import "./tooltip";
@import "./popover";
@import "./carousel";
@import "./utilities";
@import "./print";
@import "./padding";

//Includes
@import "./includes/slider";
@import "./includes/line";
@import "./includes/domaine";
@import "./includes/footer";
@import "./includes/navbar";
@import "./includes/logements";
@import "./includes/popup";
@import "./includes/floating-phone";
@import "./includes/animaux";
@import "./includes/adresses";
@import "./includes/back-to-top";
@import "./includes/map-responsive";
@import "./includes/search-home";

// Sections
@import "./sections/one";
@import "./sections/two";
@import "./sections/three";
@import "./sections/four";
@import "./sections/five";
@import "./sections/six";
@import "./sections/faq";
@import "./sections/card-logement-container";

// Utilities
@import "./utilities/flip";
@import "./utilities/zoom";
@import "./utilities/stars";
@import "./utilities/fade-in";
@import "./utilities/carousel";
@import "./utilities/pagination";

// Custom
@import "./fontawesome/fontawesome";
@import "./fontawesome/solid";
@import "./fontawesome/brands";
@import "./bhinternet_custom";
@import "./fonts/angelta-script";
@import "./fonts/great-vibes";
@import "./fonts/playfair-display";

@import "./logement-card";
@import "./card-reservations";