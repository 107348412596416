// All sections called "one"

.one {

    &_home {
        position: relative;
        top: rem(-220);

        @media (max-width: $small-mobile) {
            top: rem(-284);
        }

        &_title {

            h3,
            p, h2#sub_p_style {
                font-weight: 400;
                font-size: rem(20);
                line-height: rem(31.66);
                text-align: center;
                color: $acdf-gold;

                @media (max-width: $tablet) {
                    font-size: rem(15);
                    line-height: rem(25.66);
                }

                @media (max-width: $small-mobile) {
                    font-size: rem(13);
                    line-height: rem(23.32);
                }
            }

            h2,
            p.h2_style {
                font-weight: 400;
                font-size: rem(40);
                line-height: rem(63.32);
                text-align: center;
                color: $acdf-blue;
                padding-bottom: rem(100);

                @media (max-width: $tablet) {
                    font-size: rem(30);
                    line-height: rem(43.32);
                    padding-right: rem(30);
                    padding-left: rem(30);
                }

                @media (max-width: $small-mobile) {
                    font-size: rem(20);
                    line-height: rem(33.32);
                }
            }


        }

        &_txt {
            background-color: $acdf-low-white;
            padding: rem(60) rem(120);

            @media (max-width: $tablet) {
                padding: rem(80) rem(80);
            }

            @media (max-width: $tablet) {
                padding: rem(60) rem(32);
            }

            h2,
            h3 {
                font-weight: 400;
                font-size: rem(25);
                line-height: rem(39.57);
                text-align: center;
                color: $acdf-blue;
                padding-bottom: rem(30);

                @media (max-width: $tablet) {
                    font-size: rem(22);
                    line-height: rem(30.57);
                }

                @media (max-width: $small-mobile) {
                    font-size: rem(18);
                    line-height: rem(28.32);
                }
            }

            p {
                font-weight: 400;
                font-size: rem(16);
                line-height: rem(25.33);
                text-align: center;
                color: $acdf-grey;
                margin-bottom: 0;
                margin-block-start: 0;
                margin-block-end: 0;

                @media (max-width: $tablet) {
                    font-size: rem(15);
                    line-height: rem(20.33);
                }

                @media (max-width: $small-mobile) {
                    font-size: rem(12);
                    line-height: rem(18.32);
                }
            }
        }

        &_img {

            @media (max-width: $mobile) {
                display: flex;
                justify-content: center;
            }

            img {
                width: 100%;
            }

            &-flower {
                position: absolute;
                right: 0;
                top: -11%;
                z-index: 1;

                @media (max-width: rem(1660)) {
                    display: none;
                }
            }
        }

        &_right {
            position: relative;
            left: -7%;

            @media (max-width: $mobile) {
                left: rem(0);
            }

            @media (max-width: 1100px) {
                left: 0;
            }
        }

        .col-sm-6 {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .container,
        .row,
        .col-sm-6 {
            max-width: none;
            margin: 0;
            padding: 0;
        }
    }

    &_villa,
    &_appartement-f2-1er-etage,
    &_appartement-f3-1er-etage,
    &_appartement-f3-rdc,
    &_legnu-et-resina,
    &_proprete-et-entretien,
    &_decouvrez-la-region,
    &_livre-d-or {
        position: relative;
        top: rem(-156);

        @media (max-width: $small-mobile) {
            top: rem(-254);
        }

        &_txt {
            background-color: $acdf-low-white;
            padding: rem(60) rem(16);
            height: 100%;

            @media (max-width: $mobile) {
                padding: rem(60) rem(36) rem(0);
            }

            h2 {
                font-weight: 400;
                font-size: rem(40);
                line-height: rem(63.32);
                text-align: center;
                color: $acdf-blue;
                padding-bottom: rem(18);

                @media (max-width: $tablet) {
                    font-size: rem(30);
                    line-height: rem(43.32);
                }
            }

            h3 {
                font-weight: 400;
                font-size: rem(20);
                line-height: rem(31.66);
                text-align: center;
                color: $acdf-gold;

                @media (max-width: $tablet) {
                    font-size: rem(15);
                    line-height: rem(25.66);
                }
            }

            p {
                font-weight: 400;
                font-size: rem(16);
                line-height: rem(25.33);
                color: $acdf-grey;
                margin-bottom: 0;
                margin-block-start: 0;
                margin-block-end: 0;

                @media (max-width: $tablet) {
                    font-size: rem(15);
                    line-height: rem(20.33);
                }
            }

            a {
                background-color: $acdf-gold;
                font-weight: 400;
                font-size: rem(20);
                line-height: rem(27);
                color: $acdf-low-white;
                text-align: center;
                display: flex;
                justify-content: center;
                padding: rem(10) rem(20);
                margin: rem(40) rem(110);

                @media (max-width: $tablet) {
                    font-size: rem(15);
                    line-height: rem(20.33);
                }
            }
        }

        &_left {
            padding-left: rem(90);
            padding-right: rem(0);
        }

        &_right {
            padding-right: rem(90);
            padding-left: rem(0);
        }

        &_img {
            background-color: $acdf-low-white;
            display: flex;
            justify-content: center;
            height: rem(750);

            video {
                width: 100%;
            }

            img {
                position: relative;
                height: 100%;

                @media (max-width: $small-mobile) {
                    width: 80%;
                }
            }

            &-2 {
                display: flex;
                justify-content: center;

                img {
                    width: 80%;
                }
            }

            &-flower {
                position: absolute;
                right: 0;
                top: -9%;
                z-index: 1;

                @media (max-width: rem(1750)) {
                    display: none;
                }

                &-2 {
                    position: absolute;
                    top: 72.5%;
                    z-index: 1;

                    @media (max-width: $tablet) {
                        display: none;
                    }
                }

                &-3 {
                    position: absolute;
                    right: 0;
                    top: 85%;
                    z-index: 2;

                    @media (max-width: rem(1540)) {
                        display: none;
                    }
                }
            }

            &-divider {
                display: flex;
                justify-content: flex-start;
                position: relative;
                padding-bottom: rem(30);

                &-2 {
                    display: flex;
                    justify-content: flex-start;
                    padding-bottom: rem(30);
                    margin-left: rem(348);

                    @media (max-width: $tablet) {
                        display: none;
                    }
                }
            }
        }

        .container,
        .row {
            max-width: none;
            margin: rem(0);
            padding: rem(0);
        }

        .row {
            padding-bottom: rem(160) !important;

            @media (max-width: $small-mobile) {
                padding-bottom: rem(0) !important;
            }
        }
    }

    &_appartement-f2-1er-etage,
    &_appartement-f3-1er-etage,
    &_appartement-f3-rdc,
    &_villa {

        &_txt {

            @media (max-width: $tablet) {
                padding: rem(60) rem(120) rem(60);
            }

            @media (max-width: $small-mobile) {
                padding: rem(60);
            }

            a {
                margin: rem(40) rem(76);
                border: rem(2) solid $acdf-gold;
                transition: 0.3s ease-out;

                &:hover {
                    transform: translateY(rem(15));
                    text-decoration: none;
                    background-color: transparent;
                    margin: rem(40) rem(60);
                    border: rem(2) solid $acdf-gold;
                    color: $acdf-gold;
                }
            }
        }

        .row {

            @media (max-width: $tablet) {
                flex-direction: column-reverse;
            }
        }

        .col-sm-6 {

            @media (max-width: $tablet) {
                flex: 0 0 100%;
                max-width: 100%;
                padding-left: rem(0);
                padding-right: rem(0);
            }
        }
    }

    &_legnu-et-resina {
        padding-bottom: rem(230);
        top: rem(60);

        @media (max-width: $mobile) {
            padding-bottom: rem(100);
        }

        &_img {
            display: flex;
            justify-content: flex-end;

            img {
                width: 50%;
            }

            &-logo {
                display: flex;
                justify-content: center;
            }

            &-flower {
                top: -6%;

                &-2 {
                    top: 55%;
                }
            }
        }

        &_txt {
            margin: rem(0) rem(80);

            @media (max-width: $tablet) {
                padding: rem(60) rem(30);
            }

            @media (max-width: $mobile) {
                margin: rem(0);
            }

            .text-phone {
                background-color: transparent;
                font-weight: 900;
                font-size: rem(16);
                color: $acdf-grey;
                padding: rem(0);
                margin: rem(0);
                text-decoration: none;
            }

            p {
                text-align: center;
            }

            h2 {
                padding-bottom: rem(40);

                span {
                    margin: rem(0) rem(-5);

                    &.first-letter {
                        color: $first-letter-color;
                    }

                    // <= "L" letter for Legnu & Resina page
                    &.second-letter {
                        color: $second-letter-color;
                    }

                    // <= "E" letter for Legnu & Resina page
                    &.third-letter {
                        color: $third-letter-color;
                    }

                    // <= "G" letter for Legnu & Resina page
                    &.fourth-letter {
                        color: $fourth-letter-color;
                    }

                    // <= "N" letter for Legnu & Resina page
                    &.fifth-letter {
                        color: $fifth-letter-color;
                    }

                    // <= "U" letter for Legnu & Resina page
                    &.sixth-letter {
                        color: $sixth-letter-color; // <= "&" letter for Legnu & Resina page
                        margin: rem(0) rem(10);
                    }

                    &.seventh-letter {
                        color: $seventh-letter-color;
                    }

                    // <= "R" letter for Legnu & Resina page
                    &.eighth-letter {
                        color: $eighth-letter-color;
                    }

                    // <= "E" letter for Legnu & Resina page
                    &.ninth-letter {
                        color: $ninth-letter-color;
                    }

                    // <= "S" letter for Legnu & Resina page
                    &.tenth-letter {
                        color: $tenth-letter-color;
                    }

                    // <= "I" letter for Legnu & Resina page
                    &.eleventh-letter {
                        color: $eleventh-letter-color;
                    }

                    // <= "N" letter for Legnu & Resina page
                    &.twelfth-letter {
                        color: $twelfth-letter-color;
                    }

                    // <= "A" letter for Legnu & Resina page
                }
            }

            a {
                border: rem(2) solid $acdf-gold;
                transition: 0.3s ease-out;
                display: flex;
                justify-content: center;
                align-items: center;

                @media (max-width: $tablet) {
                    font-size: rem(15);
                    line-height: rem(20.33);
                    margin: rem(40) rem(15);
                }

                &:hover {
                    transform: translateY(rem(-20));
                    text-decoration: none;
                    background-color: transparent;
                    color: $acdf-gold;
                }

                &.text-phone {
                    border: rem(0);
                    transition: all 0.4s ease;

                    &:hover {
                        transform: translateY(rem(0));
                        animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
                        text-decoration: none;
                        background-color: transparent;
                        border: rem(0);
                        color: $acdf-grey;
                    }
                }
            }
        }

        .row {
            display: flex;
            justify-content: center;
            padding: rem(70) rem(0) rem(40) rem(0) !important;

            @media (max-width: $mobile) {
                flex-direction: column;
            }
        }
    }

    &_proprete-et-entretien {
        padding-bottom: rem(170);
        top: rem(60);

        &_img {
            display: flex;
            justify-content: flex-end;

            img {
                width: 50%;
            }

            &-flower {

                &-2 {

                    img {
                        top: rem(-676);
                    }
                }

                &-3 {

                    img {
                        left: 353%;
                        top: rem(-323);
                    }
                }
            }
        }

        &_txt {
            margin: rem(0) rem(80);
            padding: rem(60) rem(120);

            p {
                text-align: center;
            }
        }
    }

    &_decouvrez-la-region {
        top: rem(-225);

        &_txt {
            background-color: transparent;

            @media (max-width: $small-mobile) {
                padding: rem(60);
            }

            ul {

                @media (max-width: $small-mobile) {
                    padding-left: rem(0);
                }
            }
        }

        &_img {

            &-flower {
                top: -15%;

                &-2 {
                    top: -13%;
                }
            }
        }

        h2 {
            padding-bottom: rem(40);
        }

        ul {
            list-style-position: inside;
            list-style-type: none;
        }

        li {
            font-weight: 400;
            font-size: rem(16);
            line-height: rem(25.33);
            text-align: center;
            color: $acdf-grey;
            padding-bottom: rem(30);
        }
    }

    &_pre-reservation {
        padding-top: rem(100);

        .form-group {
            display: flex;
            align-items: center;
        }

        .validate {
            display: flex;
            justify-content: center;
            padding-left: rem(40);
        }

        &_btn {
            display: flex;
            justify-content: center;

            &-submit {
                background-color: #a6927b;
                font-weight: 400;
                font-size: rem(20);
                line-height: rem(27);
                color: rgba(247, 244, 240, 0.823529);
                display: flex;
                justify-content: center;
                padding: rem(10) rem(20);
                margin: rem(40) rem(110);
                border: none;

                @media (max-width: $tablet) {
                    font-size: rem(15);
                    line-height: rem(20.33);
                }
            }
        }

        &_txt {

            p {
                text-align: left;
                font-family: Poppins;
                font-weight: 400;
                font-size: rem(16);
                line-height: rem(20);
                color: $acdf-brown;
                padding: rem(0) rem(25);
                text-align: center;

                @media (max-width: $tablet) {
                    font-size: rem(15);
                    line-height: rem(20.33);
                }
            }

            &-check {
                color: $acdf-brown;
                font-family: 'Poppins';
                font-weight: 400;
                font-size: rem(13);
                line-height: rem(27);
                padding: rem(0) rem(25);
            }
        }

        &_img {

            &-point {
                padding-right: rem(15);
            }

            &-divider {

                @media (max-width: $small-mobile) {
                    padding: rem(25) rem(0);
                }

                img {
                    width: 100%;
                }
            }
        }

        .row-margin {
            padding-left: rem(40);
            padding-right: rem(20);
        }

        .did-floating {

            &-label {
                font-family: Inter;
                font-size: rem(12);
                line-height: rem(15);
                font-weight: 400;
                color: $acdf-brown;
                position: absolute;
                pointer-events: none;
                left: 5%;
                top: -15%;
                margin-bottom: rem(0);
                padding: rem(0) rem(5);
                background: $acdf-white;
                transition: 0.2s ease all;

                &-content {
                    position: relative;
                    width: 100%;
                }
            }

            &-select,
            &-textarea,
            &-input,
            &-button {
                font-size: rem(15);
                font-family: Poppins;
                font-weight: 400;
                line-height: rem(22);
                color: $acdf-brown;
                display: block;
                width: 100%;
                height: rem(50);
                padding: rem(13) rem(30);
                background: $acdf-white;
                border: rem(2) solid $acdf-low-brown;
                box-sizing: border-box;

                &:focus {
                    outline: none;

                    ~.did-floating-label {
                        top: rem(-8);
                        font-size: rem(12);
                    }
                }
            }

            &-select,
            &-textarea,
            &-input,
            &-button {

                &:not([value=""]):valid~.did-floating-label {
                    top: rem(-8);
                    font-size: rem(12);

                    @media (max-width: rem(868)) {
                        white-space: nowrap;
                    }

                    @media (max-width: $mobile) {
                        white-space: normal;
                    }
                }

                &[value=""]:focus~.did-floating-label {
                    top: rem(15);
                    font-size: rem(15);
                    font-family: Poppins;
                    font-weight: 400;
                    line-height: rem(22);
                    color: $acdf-brown;
                }
            }
        }

        textarea {
            height: rem(260) !important;
        }

        select,
        textarea,
        input,
        button {
            &.did {
                &-floating {
                    &-select {
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        appearance: none;

                        &::-ms-expand {
                            display: none;
                        }
                    }
                }
            }
        }

        .row {
            padding-bottom: rem(30);

            @media (max-width: $mobile) {
                flex-direction: column;
                padding-bottom: rem(0);
            }

            .validate {

                @media (max-width: $mobile) {
                    padding-left: rem(0);
                }
            }

            .checkbox {

                @media (max-width: $mobile) {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

        .container {
            max-width: 100% !important;
            padding: rem(0) rem(120) rem(120);

            @media (max-width: rem(1008)) {
                padding: rem(0) rem(50) rem(120);
            }

            @media (max-width: rem(868)) {
                padding: rem(0) rem(25) rem(120);
            }
        }

        small {
            color: $acdf-low-brown;
            font-style: italic;
            font-weight: 200;
        }

        button {
            height: auto !important;
            text-align: left;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
        }

        .guests-input {
            z-index: 11 !important;
        }

        .guests-input,
        .rooms-input {
            z-index: 10;

            .col-sm-6 {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                padding-right: rem(0);
                padding-left: rem(0);
            }

            button {
                cursor: pointer;

                .open {

                    &:before {
                        -webkit-transform: rotate(45deg);
                        transform: rotate(45deg);
                    }

                    &:after {
                        -webkit-transform: rotate(-45deg);
                        transform: rotate(-45deg);
                    }
                }
            }

            &__options {
                position: absolute;
                width: 100%;
                background-color: $acdf-white;
                border: rem(2) solid $acdf-low-brown;
                border-top: rem(0);
                overflow: hidden;
                height: rem(0);
                opacity: 0;

                >div {
                    padding: rem(5) rem(28);

                    @media (max-width: rem(868)) {
                        display: flex;
                        flex-direction: column;
                    }

                    &:first-child {
                        padding-top: rem(20);
                    }
                }

                &.open {
                    opacity: 1;
                    height: rem(150);

                    @media (max-width: rem(1113)) {
                        height: rem(254);
                    }

                    @media (max-width: $mobile) {
                        height: rem(200);
                    }
                }
            }

            &__ctrl {
                font-size: rem(20);
                line-height: rem(20);
                color: $acdf-brown;
                border: rem(1) solid $acdf-brown;
                padding: rem(3) rem(8);
                cursor: pointer;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                transition: all .2s ease;

                @media (max-width: rem(868)) {
                    padding: rem(1) rem(3);
                }

                &.disabled {
                    color: $acdf-low-brown;
                    border-color: $acdf-low-brown;
                    cursor: url("/assets/img/includes/cursor/2.png"), auto;
                }
            }

            &__value {
                font-size: rem(12);
                color: $acdf-brown;

                &-2 {
                    font-size: rem(10);
                    padding-left: rem(3);
                    font-style: italic;
                    color: $acdf-low-brown;
                }
            }
        }

        .rooms-input {

            &__options {

                &.open {
                    height: rem(70);

                    @media (max-width: rem(868)) {
                        height: rem(96);
                    }
                }
            }
        }

        // input[type="date"]::-webkit-inner-spin-button,
        // input[type="date"]::-webkit-calendar-picker-indicator {
        //     display: none;
        //     -webkit-appearance: none;
        // }
    }

    &_livre-d-or {

        &_title {

            h2 {
                font-weight: 400;
                font-size: rem(40);
                line-height: rem(63.32);
                text-align: center;
                color: $acdf-blue;
                padding-bottom: rem(100);

                @media (max-width: $tablet) {
                    font-size: rem(30);
                    line-height: rem(43.32);
                    padding-right: rem(30);
                    padding-left: rem(30);
                }
            }

            h3 {
                font-weight: 400;
                font-size: rem(20);
                line-height: rem(31.66);
                text-align: center;
                color: $acdf-gold;

                @media (max-width: $tablet) {
                    font-size: rem(15);
                    line-height: rem(25.66);
                }
            }
        }

        &_img {

            &-flower {
                position: absolute;
                left: 0;
                top: -16%;
                z-index: 1;

                &-2 {
                    position: absolute;
                    right: 0;
                    top: 65%;
                    z-index: 1;
                }
            }
        }

        .row {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: rem(0) !important;
        }
    }

    &_visiter-notre-atelier,
    &_voir-nos-realisations {

        &_img {

            img {
                height: rem(720);
                width: 100%;
                object-fit: cover;

                @media (max-width: $mobile) {
                    height: rem(450);
                }
            }
        }

        .container {
            max-width: 80%;
        }

        .row {
            justify-content: center;
        }
    }

    &_villa {

        &_left {

            .one_villa_img {

                .slider-wrapper {

                    .slider-item {
                        opacity: 0 !important;
                        height: 600px !important;

                        &.active {
                            opacity: 1 !important;
                        }
                    }
                }
            }
        }
    }
}

.carousel-container {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: auto;

    .carousel {
        display: flex;
        transition: transform 0.5s ease-in-out;

        .carousel-item {
            min-width: 100%;
            box-sizing: border-box;
            display: block;
        }
    }

    .carousel-button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-color: rgba(166, 146, 123, 0.7019607843);
        color: white;
        border: none;
        padding: 10px;
        cursor: pointer;
        z-index: 10;

        &.prev {
            left: 10px;
        }

        &.next {
            right: 10px;
        }
    }
}