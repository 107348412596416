// All sections called "animaux"

.animaux {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: rem(100);
        
    @media (max-width: $tablet) {
        padding-top: rem(100) !important;
    }

    &_txt {

        p {
            font-weight: 400;
            font-size: rem(20);
            line-height: rem(26.66);
            text-align: center;
            color: $acdf-brown;
            margin-bottom: rem(0);
            margin-block-start: rem(0);
            margin-block-end: rem(0);
        
            @media (max-width: $tablet) {
                font-size: rem(15);
                line-height: rem(20.33);
                padding: rem(0) rem(20);
            }
        }
    }

    &_img {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    lord-icon {
        width: rem(150);
        height: rem(150);
    }
}