// All flip elements

.flip {

    &-logements, &-logo-corse {
        position: relative;
        display: inline-block;
        width: rem(430);
        z-index: 2;
    
        @media (max-width: $tablet) {
            width: rem(238);
        }
        
        >.recto, >.verso {
            display: block;
            color: white;
            width: inherit;
            background-size: cover !important;
            background-position: center !important;
            height: rem(430);
            display: flex;
            align-items: center;
            justify-content: center;
            transition-timing-function: cubic-bezier(.175, .885, .32, 1.275);
            transition-duration: .9s;
            transition-property: transform, opacity;
    
            @media (max-width: $tablet) {
                height: rem(222);
            }
        }
        
        >.recto {
            transform: rotateY(0deg);
        }
        
        >.verso {
            position: absolute;
            opacity: 0;
            top: rem(0);
            left: rem(0);
            width: 100%;
            height: 100%;
            transform: rotateY(-180deg);
        }
        
        &:hover {
            
            >.recto {
                transform: rotateY(180deg);

                &_txt {
                    opacity: 0;
                }
            }
            
            >.verso {
                opacity: 1;
                transform: rotateY(0deg);
            }
        }
    }

    &-logo {
        
        &-corse {
            display: flex !important;
            justify-content: center !important;
            width: 100% !important;
            padding: rem(60) rem(0);
        
            >.recto, >.verso {
                width: 4% !important;
                height: rem(74) !important;
        
                @media (max-width: $mid-screen) {
                    width: 6% !important;
                }
        
                @media (max-width: $tablet) {
                    width: 8% !important;
                }
        
                @media (max-width: $mobile) {
                    width: 11% !important;
                }
        
                @media (max-width: $small-mobile) {
                    width: 21% !important;
                }
            }
            
            >.verso {
                width: 4% !important;
        
                @media (max-width: $mid-screen) {
                    width: 6% !important;
                }
        
                @media (max-width: $tablet) {
                    width: 8% !important;
                }
        
                @media (max-width: $mobile) {
                    width: 11% !important;
                }
        
                @media (max-width: $small-mobile) {
                    width: 21% !important;
                }
            }
            
            &:hover {
                
                >.verso {
                    opacity: 0 !important;
                }
            }
        }
    }
}