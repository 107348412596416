.physicianList {
    padding: rem(100) rem(0);
    
    ul {
        display: flex;
        flex-wrap: wrap;
        padding-inline-start: rem(0);

        @media (max-width: $mobile) {
            flex-direction: column;
        }

        li {
            width: calc(100% / 3);
            padding: rem(10);
            list-style-type: none;

            @media (max-width: $mobile) {
                width: 100%;
            }
        }
    }
}

#page_navigation {
    clear: both;
    margin: rem(20) rem(0);

    a {
        padding: rem(3) rem(6);
        border: rem(1) solid $acdf-gold;
        margin: rem(5);
        color: $acdf-gold;
        text-decoration: none
    }
}

.active_page {
    background: $acdf-gold;
    color: white !important;
}