// All addresses

.adresses {
    display: flex;
    justify-content: center;
    position: relative;
    top: rem(-150);
    padding-bottom: rem(150);

    @media (max-width: $tablet) {
        padding-bottom: rem(28);
    }

    &_title {
        padding-bottom: rem(80);

        h2 {
            color: $acdf-blue;
            font-size: rem(40);
            font-weight: 400;
            line-height: rem(47.49);
            text-align: center;

            @media (max-width: $tablet) {
                font-size: rem(30);
                line-height: rem(43.32);
            }
        }
    }

    &_txt {

        p {
            color: $acdf-gold;
            font-size: rem(20);
            font-weight: 400;
            line-height: rem(31.66);
            text-align: center;
            padding: rem(80) rem(0);
            margin-bottom: 0;
            margin-block-start: 0;
            margin-block-end: 0;

            @media (max-width: $tablet) {
                font-size: rem(15);
                line-height: rem(20.33);
                padding: rem(80) rem(80);
            }

            @media (max-width: $mobile) {
                padding: rem(36) rem(0);
            }
        }
    }

    &_button {
        display: flex;
        justify-content: center;

        @media (max-width: $mobile) {
            margin: rem(20) rem(10);
            flex-direction: column;
        }

        &-2 {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;

            @media (max-width: $mobile) {
                display: flex;
                justify-content: center;
                flex-direction: column;
            }
        }
    }

    .selectSection {
        justify-content: center;
        text-align: center;

        button {
            background-color: $acdf-gold;
            font-weight: 400;
            font-size: rem(20);
            line-height: rem(27);
            color: $acdf-low-white;
            padding: rem(10) rem(20);
            margin: rem(0) rem(10);
            border: rem(2) solid $acdf-gold;
            filter: drop-shadow(rem(0) rem(4) rem(10) rgba(0, 0, 0, 0.39));
            outline: 0;
            cursor: pointer;
            transition: 0.3s ease-out;

            @media (max-width: $tablet) {
                font-size: rem(15);
                margin-top: rem(0) !important;
            }

            @media (max-width: rem(1199)) {
                margin-top: rem(30);
            }

            @media (max-width: $mobile) {
                margin: rem(20) rem(10);
            }

            &:hover {
                transform: translateY(rem(-5));
                text-decoration: none;
                background-color: transparent;
                border: rem(2) solid $acdf-gold;
                color: $acdf-gold;
            }

            &:focus {
                background-color: $acdf-blue;
                font-weight: 400;
                font-size: rem(20);
                line-height: rem(27);
                color: $acdf-low-white;
                padding: rem(10) rem(20);
                border: rem(2) solid $acdf-blue;
                border-radius: rem(0);
                outline: 0;
                transition: 0.3s ease-out;
            }
        }
    }

    .active2 {
        background-color: $acdf-blue !important;
        font-weight: 400 !important;
        font-size: rem(20) !important;
        line-height: rem(27) !important;
        color: $acdf-low-white !important;
        padding: rem(10) rem(20) !important;
        border: rem(2) solid $acdf-blue !important;
        transition: 0.3s ease-out !important;

        @media (max-width: $tablet) {
            font-size: rem(15) !important;
        }

        &:hover {
            transform: translateY(rem(-5)) !important;
            text-decoration: none !important;
            background-color: transparent !important;
            border: rem(2) solid $acdf-blue !important;
            color: $acdf-blue !important;
        }
    }

    .row {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: rem(40);

        @media (max-width: $mobile) {
            padding-bottom: rem(0);
        }
    }

    .content {
        display: flex;
        justify-content: center;

        .adresses_button, .adresses_button-2 {
            p {
                background-color: $acdf-white;
                font-weight: 400;
                font-size: rem(20);
                line-height: rem(27);
                color: $acdf-blue;
                text-align: center;
                padding: rem(10) rem(20);
                margin: rem(0) rem(20);
                border: rem(2) solid $acdf-white;
                filter: drop-shadow(rem(0) rem(4) rem(10) rgba(0, 0, 0, 0.39));
                outline: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: 0.3s ease-out;

                @media (max-width: $tablet) {
                    font-size: rem(15);
                }

                @media (max-width: $mobile) {
                    margin: rem(8) rem(10);
                }

                &:hover {
                    transform: translateY(rem(-5));
                    text-decoration: none;
                    background-color: $acdf-blue;
                    border: rem(2) solid $acdf-blue;
                    color: $acdf-low-white;
                }
            }
        }

        &:not(:first-child) {
            display: none;
        }
    }
}