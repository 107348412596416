// All sections called "footer"

.footer {
    background-color: $acdf-beige;
    padding: rem(80) rem(0);

    &_title {

        h2, p.h2_style_footer {
            font-family: 'Great-Vibes' !important;
            font-weight: 400;
            font-size: rem(51);
            line-height: rem(70.99);
            text-align: center;
            color: $acdf-gold;
            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none;
        
            @media (max-width: $mid-screen) {
                font-size: rem(41);
                line-height: rem(60.99);
            }

            a {
                font-family: 'Angelta-Script' !important;
                font-weight: 400;
                font-size: rem(51);
                line-height: rem(70.99);
                text-align: center;
                color: $acdf-gold;
                -webkit-user-select: none;
                -ms-user-select: none;
                user-select: none;
                text-decoration: none;
        
                @media (max-width: $mid-screen) {
                    font-size: rem(41);
                    line-height: rem(60.99);
                }
        
                @media (max-width: $small-mobile) {
                    font-size: rem(30);
                    line-height: rem(30.33);
                }
            }
        }
    }

    &_txt {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        
        @media (max-width: rem(1211)) {
            flex-direction: column;
            max-width: 30%;
        }
        
        @media (max-width: $small-mobile) {
            align-items: center;
            max-width: 30%;
        }

        h3, p:not(.real-p), a {
            font-weight: 400;
            font-size: rem(27);
            line-height: rem(42.74);
            text-align: center;
            color: $acdf-blue;
            padding: rem(0) rem(10);
            text-transform: uppercase;
            white-space: nowrap;
            transition: 0.3s ease-out;
            margin-bottom: 0;
            
            @media (max-width: $mid-screen) {
                font-size: rem(20);
                line-height: rem(35.74);
            }
        
            @media (max-width: rem(1211)) {
                text-align: left;
                padding: rem(0) rem(60);
            }
        
            @media (max-width: $tablet) {
                padding: rem(0) rem(28);
            }
        
            @media (max-width: $small-mobile) {
                padding: rem(0);
            }

            &:hover {
                transform: translateY(rem(-5));
                color: $acdf-gold;
            }            
        }

        p.real-p {
            font-family: Roboto;
            font-weight: 600;
            font-size: rem(16);
            line-height: rem(25.33);
            text-align: left;
            color: $acdf-blue;
            margin-bottom: rem(0);
            margin-block-start: rem(0);
            margin-block-end: rem(0);
            text-transform: uppercase;
        
            @media (max-width: $mid-screen) {
                font-size: rem(13);
                line-height: rem(20.33);
            }
        }
    }

    &_img {

        &-logo {
            display: flex;
            justify-content: center;
            padding-top: rem(30);
            padding-bottom: rem(80);
        }

        &-divider {
            display: flex;
            align-items: flex-start;
            justify-content: center;
                
            @media (max-width: $mobile) {
                width: 60%;
            }

            img {
                padding: rem(10) rem(0);
                
                @media (max-width: $mobile) {
                    width: 100%;
                }
            }
        }

        &-icon {
            display: flex;
            justify-content: center;

            img {
                width: 50%;
                transition: 0.3s ease-out;
    
                &:hover {
                    transform: translateX(rem(5));
                }
            }

            &-2 {
                
                @media (max-width: rem(1211)) {
                    display: flex;
                    justify-content: flex-end;
                    padding: rem(40) rem(0);
                }
        
                @media (max-width: $small-mobile) {
                    justify-content: center;
                }
            }

            a {
        
                @media (max-width: $small-mobile) {
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }

    .col-sm-4 {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        padding: rem(0) rem(90) !important;
        
        @media (max-width: rem(1376)) {
            padding: rem(0) !important;
        }
    
        @media (max-width: rem(1211)) {
            align-items: flex-end;
        }
        
        @media (max-width: $small-mobile) {
            align-items: center;
        }
    }

    .col-sm-1 {
    
        @media (max-width: rem(1211)) {
            align-items: flex-end;
        }

        img {
            width: 100%;
            transition: 0.3s ease-out;
                
            @media (max-width: rem(1211)) {
                width: 20%;
            }

            &:hover {
                transform: translateY(rem(5));
            }
        }
    }

    .container, .row, .col-sm-4, .col-sm-1 {
        max-width: none;
        margin: rem(0);
        padding: rem(0);
    }

    .row {
        padding-top: rem(50);
        align-items: center;
        justify-content: center;
    
        @media (max-width: rem(1211)) {
            flex-direction: column;
            align-items: flex-end;
            padding-top: rem(0);
            padding-right: rem(120);
        }
        
        @media (max-width: $tablet) {
            padding-right: rem(56);
        }
        
        @media (max-width: $small-mobile) {
            padding-right: rem(0);
            padding-top: rem(50);
        }
    }

    h3, p {
        
        &#apartments2-submenu {
    
            @media (max-width: rem(1211)) {
                padding-left: rem(120);
            }
            
            @media (max-width: $tablet) {
                padding-left: rem(56);
            }
            
            @media (max-width: $small-mobile) {
                padding-left: rem(0);
                padding-right: rem(0);
            }
        }
    }

    #apartments2-submenu {
        
        &-bottom {
        
            @media (max-width: $small-mobile) {
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            a {
                font-size: rem(20);
        
                @media (max-width: $mid-screen) {
                    font-size: rem(15);
                    line-height: rem(20.33);
                }

                &:hover {
                    color: $acdf-gold !important;
                }
            }

            h3, p {
                padding: rem(0) rem(10);
                margin-top: rem(-26);
                transition: 0.3s ease-out;
        
                @media (max-width: $mid-screen) {
                    margin-top: rem(-22);
                }
        
                @media (max-width: rem(1211)) {
                    padding: rem(0) rem(70);
                }
        
                @media (max-width: $tablet) {
                    padding-left: rem(38);
                }
        
                @media (max-width: $small-mobile) {
                    padding-left: rem(0);
                    padding-right: rem(0);
                }

                &:hover {
                    transform: translateX(rem(5));
                }
            }
        }
    }
    
    .phone-shake {

        &:hover {
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            transform: translate3d(0, 0, 0);
            backface-visibility: hidden;
            perspective: rem(1000);
        }

        @keyframes shake {
    
            10%, 90%      { transform: translate3d(-1px, 0, 0); }
            20%, 80%      { transform: translate3d( 2px, 0, 0); }
            30%, 50%, 70% { transform: translate3d(-4px, 0, 0); }
            40%, 60%      { transform: translate3d( 4px, 0, 0); }
        }
    }

    .st-first  { fill: $acdf-gold-2; }
	.st-second { fill: $acdf-gold-3; }
	.st-third  { fill: $acdf-gold-2; }
	.st-fourth { fill: $acdf-gold;   }
	.st-fifth  { fill: $acdf-gold;   }

    #mail-icon {
        width: rem(25);
        cursor: pointer;
    }

    .center-all {
        
        @media (max-width: 1211px) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: rem(40);
        }
        
        @media (max-width: $small-mobile) {
            flex-direction: column;
        }
    }
}