// All sections called "two"

.two {

    &_home {
        position: relative;
        top: rem(-220);

        &_title {

            position: relative;

            h2, p.h2_style {
                font-weight: 400;
                font-size: rem(40);
                line-height: rem(63.32);
                text-align: center;
                color: $acdf-blue;
                padding-top: rem(100);
        
                @media (max-width: $tablet) {
                    font-size: rem(30);
                    line-height: rem(43.32);
                }
        
                @media (max-width: $small-mobile) {
                    font-size: rem(20);
                    line-height: rem(33.32);
                    padding-top: rem(0);
                }
            }

            h3 {
                font-weight: 400;
                font-size: rem(20);
                line-height: rem(31.66);
                text-align: center;
                color: $acdf-gold;
                // padding-top: rem(100);
            }
        }

        &_txt {
            background-color: $acdf-low-white;
            padding: rem(60) rem(87) rem(194);
            margin-left: rem(67);
        
            @media (max-width: $mobile) {
                margin-left: rem(0);
                padding: rem(60) rem(32);
            }

            h2, h3 {
                font-weight: 400;
                font-size: rem(25);
                line-height: rem(39.57);
                text-align: center;
                color: $acdf-blue;
                padding-bottom: rem(30);
        
                @media (max-width: $tablet) {
                    font-size: rem(22);
                    line-height: rem(30.57);
                }
        
                @media (max-width: $small-mobile) {
                    font-size: rem(18);
                    line-height: rem(28.32);
                }
            }

            p {
                font-weight: 400;
                font-size: rem(16);
                line-height: rem(25.33);
                text-align: center;
                color: $acdf-grey;
                margin-bottom: rem(0);
                margin-block-start: rem(0);
                margin-block-end: rem(0);
        
                @media (max-width: $tablet) {
                    font-size: rem(15);
                    line-height: rem(20.33);
                }
        
                @media (max-width: $small-mobile) {
                    font-size: rem(13);
                    line-height: rem(18.32);
                }
            }

            a {
                font-weight: 600;
                font-size: rem(20);
                line-height: rem(31.66);
                color: $acdf-grey;
                text-decoration: none;
                display: flex;
                justify-content: center;
                padding-top: rem(50);
                transition: 0.3s ease-out;
        
                @media (max-width: $tablet) {
                    font-size: rem(15);
                    line-height: rem(20.33);
                }
        
                @media (max-width: $small-mobile) {
                    font-size: rem(13);
                    line-height: rem(18.32);
                }

                &:hover {
                    transform: translateY(rem(-5));
                    color: $acdf-gold;
                }
            }
        }

        &_left {
            position: relative;
            left: 4%;
        
            @media (max-width: $mobile) {
                left: rem(0);
            }
        }

        &_img {
            width: 100%;
            height: 100%;

        
            @media (max-width: $mobile) {
                display: none;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
        
                @media (max-width: $mobile) {
                    width: 50%;
                }
            }

            &-line {
                display: flex;
                align-items: center;
                justify-content: center;
                padding-bottom: rem(40);
        
                @media (max-width: $small-mobile) {
                    padding-bottom: rem(0);
                }
            }

            &-2 {
                display: flex;
                justify-content: center;
                position: relative;
                top: rem(-231);
        
                @media (max-width: rem(1584)) {
                    top: rem(-170);
                }
        
                @media (max-width: $mobile) {
                    position: static;
                    padding-top: rem(20);
                }

                img {
                    border: rem(23) solid $acdf-low-white;
                    width: 35%;
                    margin: auto 0px;
                    min-width: 300px;
        
                    @media (max-width: $mobile) {
                        border: none;
                        width: 100%;
                    }
                }
            }
            
            &-flower {
                position: absolute;
                right: 0;
                bottom: 0px;
                z-index: 1;
        
                @media (max-width: rem(1150)) {
                    display: none;
                }

                &-2 {
                    position: absolute;
                    top: 60.8%;
                    z-index: 1;
        
                    @media (max-width: rem(1230)) {
                        display: none;
                    }
                }
            }
        }

        .col-sm-6 {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .container, .row, .col-sm-6 {
            max-width: none;
            margin: rem(0);
            padding: rem(0);
        }
    }

    &_villa, &_appartement-f2-1er-etage, &_appartement-f3-1er-etage, &_appartement-f3-rdc, &_decouvrez-la-region {
        position: relative;
        top: rem(-120);

        &_txt {
            display: flex;
            justify-content: center;

            a {
                background-color: $acdf-gold;
                font-weight: 400;
                font-size: rem(20);
                line-height: rem(27);
                color: $acdf-low-white;
                display: flex;
                justify-content: center;
                padding: rem(10) rem(20);
                margin: rem(40) rem(110);
                border: rem(2) solid $acdf-gold;
                transition: 0.3s ease-out;
        
                @media (max-width: $tablet) {
                    font-size: rem(15);
                    line-height: rem(20.33);
                }

                @media only screen and (max-width: rem(1422)) and (min-width: $tablet) {
                    margin: rem(40) rem(0);
                }

                &:hover {
                    text-decoration: none;
                    background-color: transparent;
                    padding: rem(10) rem(40);
                    border: rem(2) solid $acdf-gold;
                    color: $acdf-gold;
                }
            }

            &-2 {
                background-color: $acdf-low-blue;
                padding: rem(42) rem(90) rem(10) rem(90);
                position: relative;
                top: 10%;
                left: -5%;
                z-index: 1;
        
                @media (max-width: $tablet) {
                    left: 0;
                }
        
                @media (max-width: $small-mobile) {
                    padding: rem(42) rem(30) rem(10) rem(30);
                }

                h2 {
                    font-weight: 400;
                    font-size: rem(42);
                    line-height: rem(55.99);
                    color: $acdf-low-white;
        
                    @media (max-width: $mobile) {
                        font-size: rem(30);
                        line-height: rem(30.33);
                    }
                }

                ul {
                    padding-inline-start: rem(20);
                    
                    li {
                        font-weight: 400;
                        font-size: rem(15);
                        line-height: rem(25.5);
                        color: $acdf-low-white;
                        margin-bottom: 0;
                        margin-block-start: 0;
                        margin-block-end: 0;
                    }
                }
            }
        }

        &_img {
            display: flex;
            justify-content: flex-end;
        
            @media (max-width: $tablet) {
                justify-content: center;
                padding-top: rem(60);
            }

            img {
                width: 100%;
        
                @media (max-width: $tablet) {
                    width: 50% !important;
                }
            }
            
            &-flower {
                position: absolute;

                img {
                    position: relative;
                    width: 100%;
                    left: 166%;
                    top: rem(-404);
                    z-index: 2;
                }
            }

            &-divider {
                display: flex;
                justify-content: flex-start;
                position: relative;
                padding: rem(20) rem(0);
            }
        }

        &_right {
            position: relative;
            padding-right: rem(90) !important;
        }

        &_left {
            position: relative;
            padding-left: rem(138) !important;
        }

        .container, .row, .col-sm-5, .col-sm-7 {
            max-width: none;
            margin: 0;
            padding: 0;
        
            @media (max-width: $tablet) {
                flex: 0 0 100%;
                max-width: 100%;
                padding-right: 0 !important;
                padding-left: 0 !important;
            }
        }

        .row {
        
            @media (max-width: $tablet) {
                flex-direction: column-reverse;
            }
        }
    }
    
    &_decouvrez-la-region {
        position: relative;
        top: rem(-135);
        
        @media (max-width: $tablet) {
            top: rem(0);
        }

        &_txt {
            display: flex;
            align-items: center;
            background-color: $acdf-low-white;
            padding: rem(60) rem(87) rem(0);
            height: 100%;
        
            @media (max-width: $tablet) {
                padding: rem(60) rem(167) rem(0);
            }
        
            @media (max-width: $mobile) {
                padding: rem(60) rem(36) rem(0);
            }
        
            h2 {
                font-style: italic;
                font-weight: 500;
                font-size: rem(30);
                line-height: rem(47.49);
                text-align: center;
                color: $acdf-blue;
                padding-bottom: rem(50);
        
                @media (max-width: $tablet) {
                    font-size: rem(20);
                    line-height: rem(30.33);
                }
            }
        }

        &_img {
        
            @media (max-width: $tablet) {
                background-color: $acdf-low-white;
            }

            iframe {
        
                @media (max-width: $tablet) {
                    max-width: 50%;
                }
            }

            img {
                width: 100%;
                position: relative;
                height: 100%;
            }
            
            &-flower {
                position: absolute;
                right: 0;
                z-index: 1;
        
                @media (max-width: $tablet) {
                    display: none;
                }

                img {
                    position: static;
                    width: auto;
                    left: auto;
                    top: auto;
                    z-index: auto;
                }

                &-2 {
                    position: absolute;
                    top: 30%;
                    z-index: 2;
        
                    @media (max-width: $tablet) {
                        display: none;
                    }
    
                    img {
                        position: static;
                        width: auto;
                        left: auto;
                        top: auto;
                        z-index: auto;
                    }
                }
            }
        }

        .row {
            padding: rem(0) rem(200) rem(160) rem(200);
        
            @media (max-width: $tablet) {
                flex-direction: column;
            }
        }

        .col-sm-4, .col-sm-8 {
            padding-right: rem(0);
            padding-left: rem(0);
          
            @media (max-width: $tablet) {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
    }

    &_pre-reservation {
        padding-bottom: rem(100);

        .container {
            max-width: rem(700);
        }

        &_txt {

            h3 {
                font-family: Poppins;
                font-weight: bold;
                font-size: rem(13);
                line-height: rem(20.44);
                color: $acdf-brown;
                text-transform: uppercase;

                @media (max-width: $mobile) {
                    text-align: center;
                }
            }

            p, a {
                font-family: Poppins;
                font-weight: 400;
                font-size: rem(13);
                line-height: rem(26.56);
                color: $acdf-brown;
                text-align: center;
                margin-bottom: rem(0);
                margin-block-start: rem(0);
                margin-block-end: rem(0);
            }

            a {
                text-decoration: underline;
                transition: 0.3s ease-out;

                &:hover {
                    transform: translateY(rem(-5));
                    color: $acdf-blue;
                }
            }

            .text-phone {
                text-decoration: none;
            }
        }

        &_img {

            &-divider {

                img {
                    width: 100%;
                }
            }

            &-flower {
                position: absolute;
                left: 0;
                top: 130%;
                z-index: 1;
        
                @media (max-width: $tablet) {
                    display: none;
                }
                
                &-2 {
                    position: absolute;
                    right: 0;
                    top: 125%;
                    z-index: 1;
        
                    @media (max-width: $tablet) {
                        display: none;
                    }
                }
            }
        }

        .row {
            align-items: center;
            padding: rem(30) rem(0);
        }
    }
}