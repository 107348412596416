// All about back to top includes

.backTotop {
    z-index: 999;
    position: fixed;
    right: rem(32);
    bottom: rem(142);
    width: rem(50);
    margin: auto;
    color: #fff;
    font-size: rem(25);
    line-height: rem(45);
    text-align: center;
    height: rem(50);
    border-radius: rem(30);
    background-color: $acdf-low-gold;
    opacity: 0;
    box-shadow: rem(0) rem(5) rem(23) rem(-6) rgba(0,0,0,0.75);
    -webkit-transition: all .7s ease-in-out;
    -moz-transition: all .7s ease-in-out;
    -ms-transition: all .7s ease-in-out;
    -o-transition: all .7s ease-in-out;
    transition: all .7s ease-in-out;

    &.active {
        opacity: 1;
        -webkit-transition: all .7s ease-in-out;
        -moz-transition: all .7s ease-in-out;
        -ms-transition: all .7s ease-in-out;
        -o-transition: all .7s ease-in-out;
        transition: all .7s ease-in-out;
    }

    a {
        color: #fff;
        width: 100%;
        display: block;
    }
}