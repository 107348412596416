// All sections called "logements"

.logements {
    position: relative;
    top: rem(100);
    padding-bottom: rem(250);

    &_title {

        h2 {
            font-size: rem(40);
            font-weight: 400;
            line-height: rem(63.32);
            text-align: center;
            color: $acdf-blue;
            padding-top: rem(40);
        
            @media (max-width: $mobile) {
                font-size: rem(25);
                line-height: rem(30.33);
            }
        }
    }

    h3, a {
        font-size: rem(42);
        font-weight: 400;
        line-height: rem(55.99);
        color: white;
        z-index: 2;
        text-decoration: none;
        text-align: center;
        
        @media (max-width: $tablet) {
            font-size: rem(30);
            line-height: rem(43.32);
        }
        
        @media (max-width: $mobile) {
            font-size: rem(20);
            line-height: rem(20.33);
        }
    }

    &_img {

        &-line {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &-divider {
            display: flex;
            justify-content: center;
            padding-top: rem(15);
            padding-bottom: rem(60);
        }
            
        &-flower {
            position: absolute;
            right: 0;
            z-index: 1;
            top: -11%;
    
            @media (max-width: $tablet) {
                display: none;
            }
        }
    }

    .container, .row, .col-sm-4 {
        max-width: none;
        margin: 0;
        padding: 0;
    }

    .row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
}