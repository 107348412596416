// Imports
@import "./_scss/bootstrap";


.dropdown.active {
    a.dropdown-item {
        color: $body-color !important;

        &.active {
            color: $white !important;
        }
    }
}

body {
    max-width: 100vw;
    overflow-x: hidden;
}

h2.h2_pp {
    font-weight: 400;
    font-size: 2.5rem;
    line-height: 3.9575rem;
    text-align: center;
    color: #44828c;
    padding-bottom: 1.125rem;
}

.theme {
    color: $acdf-gold;
}

.flash-notification {
    position: relative;
    margin: 15px auto;
    max-width: 80%;
    z-index: 1;
}

.button-basic {
    background-color: $acdf-gold;
    border: 1px solid $acdf-gold;
    color: #fff;

    &:hover {
        background-color: #968a7d;
    }
}

a.button-basic {
    color: #fff;
}

.big {
    font-size: 25px;
}

.little-big {
    font-size: 18px;
    font-weight: 800;
}

.little {
    font-size: 13px;
    font-style: italic;
}

.prices {
    padding: 10px 50px;
    background-color: rgba(211, 211, 211, .5);
}