/*!
 * Note of the author
 * Check this out for other premium fonts at My Store:
 https://www.creativefabrica.com/designer/letterfreshstudio/ref/177597
 * Thank You.
 */

@font-face {
    font-family: 'Angelta-Script';
    font-style: normal;
    font-weight: 400;
    src: url("/assets/font/angelta-script/Angelta Script.ttf");
}