// All sections called "navbar"

.navbar {

    &_title {

        h2,
        p,
        span {
            font-family: 'Great-Vibes' !important;
            font-weight: 400;
            font-size: rem(90);
            line-height: rem(70.99);
            text-align: center;
            color: $white;
            padding-top: rem(45);
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none;

            @media (max-width: $small-mobile) {
                padding-top: rem(0);
                font-size: rem(90);
            }

            a {
                font-family: 'Great-Vibes' !important;
                font-weight: 400;
                font-size: rem(90);
                line-height: rem(70.99);
                text-align: center;
                color: $acdf-white;
                -webkit-user-select: none;
                -ms-user-select: none;
                user-select: none;
                text-decoration: none;

                @media (max-width: rem(1036)) {
                    font-size: rem(50);
                    line-height: rem(48.99);
                }

                @media (max-width: $small-mobile) {
                    font-size: rem(27);
                    line-height: rem(20);
                }

                @media (max-width: 370px) {
                    font-size: rem(16);
                    line-height: rem(20);
                }
            }
        }
    }

    &_txt {

        span.acasa {
            font-family: 'Great-Vibes' !important;
            font-weight: 400;
            font-size: rem(90);
            line-height: rem(70.99);
            text-align: center;
            color: $acdf-gold;
            // padding: rem(60);
            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none;

            @media (max-width: $tablet) {
                font-size: rem(50);
                line-height: rem(48.99);
            }

            @media (max-width: $small-mobile) {
                padding: rem(0);
            }

            a {
                font-family: 'Great-Vibes' !important;
                font-weight: 400;
                font-size: rem(90);
                line-height: rem(70.99);
                text-align: center;
                color: $acdf-gold;
                padding: rem(60);
                text-transform: none;
                -webkit-user-select: none;
                -ms-user-select: none;
                user-select: none;
                text-decoration: none;

                @media (max-width: $tablet) {
                    font-size: rem(50);
                    line-height: rem(48.99);
                }

                @media (max-width: $small-mobile) {
                    font-size: rem(35);
                    line-height: rem(33);
                    padding: rem(35);
                }
            }

            @media (max-width: $mobile) {
                font-size: rem(30);
            }
        }

        h2,
        p,
        a,
        li {
            font-weight: 400;
            // font-size: rem(39);
            font-size: rem(30);
            text-align: center;
            color: $acdf-gold;
            padding: rem(4) rem(10);
            text-transform: uppercase;
            display: block;
            text-decoration: none;
            transition: 0.5s ease-out;
            margin-block-end: 0;

            &:hover {
                transform: translateY(rem(-5));
                color: $acdf-blue;
            }

            @media (max-width: $tablet) {
                font-size: rem(30);
            }

            @media (max-width: $mobile) {
                font-size: rem(20);
                padding: rem(0);
            }
        }
    }

    &_img {

        &-logo {
            display: flex;
            justify-content: center;
            padding-top: rem(30);
            padding-bottom: rem(80);
            position: absolute;
            left: 5%;
            top: rem(15);
            max-width: 100%;
            width: rem(300);
            transform: translateX(-50%);

            @media (max-width: $small-mobile) {
                padding-bottom: rem(0);
                top: rem(0);
                left: 10%;
            }

            img {

                @media (max-width: $small-mobile) {
                    width: 15%;
                }
            }
        }
    }
}

#navbar {
    z-index: 999;
    position: fixed;
    left: calc(100vw + 15px);
    top: rem(0);
    width: 100vw;
    transition: 0.5s ease-out;

    &-content {
        background-image: url("/assets/img/includes/navbar/background.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow-y: scroll;

        @media (max-width: $mobile) {
            width: 100vw;
            padding: rem(100) rem(50) rem(50) rem(50);
        }
    }

    &.open {
        left: 0vw;
    }

    &-menu {
        position: fixed;
        z-index: 999;
        top: rem(25);
        right: rem(35);
        width: rem(60);
        height: rem(44);
        transition: margin-top, 0.5s ease-out;
        cursor: pointer;
        border: rem(1) solid $white;
        border-radius: rem(5);

        @media (max-width: $small-mobile) {
            border: none;
            right: rem(5);
        }

        div {
            background: $acdf-white;
            width: rem(30);
            height: rem(1);
            border-radius: rem(10);
            margin: rem(9) rem(14);
            transition: 0.5s ease-out;
        }

        &.white {
            background-color: $acdf-low-gold;
            right: auto;
            top: auto;
            width: 100%;
            height: rem(75);
            border: rem(0);
            border-radius: rem(0);

            @media (max-width: $small-mobile) {
                height: rem(44);
            }

            &::before {
                content: url("/assets/img/includes/navbar/logo-corse.svg");
                position: relative;
                left: 3%;
                top: 15%;

                @media (max-width: $small-mobile) {
                    zoom: 0.5;
                    top: 20%;
                }
            }

            &::after {
                content: "A Casa di Furtunatu";
                font-family: 'Great-Vibes' !important;
                text-align: center;
                font-weight: 400;
                font-size: rem(55);
                line-height: rem(70.99);
                color: $white;
                position: relative;
                top: -130%;
                display: flex;
                justify-content: center;

                @media (max-width: $tablet) {
                    font-size: rem(40);
                    line-height: rem(50.99);
                    top: -120%;
                }

                @media (max-width: $small-mobile) {
                    font-size: rem(22);
                    line-height: rem(20);
                    top: -115%;
                }
            }

            &:hover {
                transform: scale(1);
            }

            div {
                background: $white;
                height: rem(2);
                position: relative;
                left: 94%;
                top: -60%;

                @media (max-width: $tablet) {
                    left: 90%;
                }

                @media (max-width: $small-mobile) {
                    left: 85%;
                    top: -60%;
                    margin: rem(6) rem(14);
                }
            }
        }

        &.open {
            background-color: transparent;
            width: rem(60);
            height: rem(50);
            border: 0;
            border-radius: 0;
            top: rem(25);
            right: rem(35);

            &::before {
                display: none;
            }

            &::after {
                display: none;
            }

            div {
                background: $acdf-gold;
                width: rem(60);
                height: rem(4);
                border-radius: rem(10);
                margin: rem(10) rem(10);

                &:first-child {
                    transform: rotate(45deg) translate(rem(10), rem(10));
                }

                &:nth-child(2) {
                    transform: rotate(-45deg) translateX(rem(-1));
                }

                &:nth-child(3) {
                    opacity: 0;
                }
            }
        }

        &.open.white {

            div {
                left: auto;
                top: auto;
            }
        }

        &:hover {
            transform: scale(0.9);
        }
    }

    #apartments-submenu {

        &-bottom {

            a {
                font-size: rem(25);

                @media (max-width: $small-mobile) {
                    font-size: rem(15);
                    line-height: rem(13);
                    padding-bottom: rem(5);
                }
            }

            h3 {
                padding: rem(0) rem(10);
                margin-bottom: rem(0);
            }
        }
    }
}