// All carousel

.one-carousel {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: rem(200);
    position: relative;
    top: rem(-150);
    
    @media (max-width: $tablet) {
        top: rem(-38);
    }

    .carousel {
    
        &_images {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                height: 45rem;
                width: 75rem;
                object-fit: cover;
            }
        }
    }

    .nav {
        
        &-left, &-right {
            position: absolute;
            color: $acdf-gold !important;
            font-size: rem(64);
            margin: rem(0) rem(50);
        }

        &-left {
            top: 50%;
            left: 0%;
        }

        &-right {
            top: 50%;
            right: 0%;
        }
    }
}