// All sections called "domaine"

.domaine {
    position: relative;
    top: rem(-220);

    &_title {

        h2 {
            font-weight: 400;
            font-size: rem(40);
            line-height: rem(63.32);
            text-align: center;
            color: $acdf-blue;
        
            @media (max-width: $tablet) {
                font-size: rem(30);
                line-height: rem(43.32);
            }
        }

        h3, a {
            font-weight: 400;
            font-size: rem(20);
            line-height: rem(31.66);
            text-align: center;
            color: $acdf-gold;
            transition: 0.3s ease-out;
        
            @media (max-width: $tablet) {
                font-size: rem(15);
                line-height: rem(25.66);
                padding: rem(0) rem(10);
            }

            &:hover {
                transform: translateY(rem(-5));
                text-decoration: none;
            }
        }
    }

    &_txt {
        background-color: $acdf-low-white;
        padding: rem(60) rem(120);
        position: relative;
        left: 24%;
        top: 11%;
        z-index: 1;
        
        @media (max-width: $tablet) {
            padding: rem(60) rem(60);
        }
            
        @media (max-width: $mobile) {
            position: relative;
            left: rem(0);
            top: rem(-77);
        }

        h2, p.h2_style_2 {
            font-weight: 400;
            font-size: rem(25);
            line-height: rem(39.57);
            text-align: center;
            color: $acdf-blue;
            padding-bottom: rem(30);
        
            @media (max-width: $tablet) {
                font-size: rem(22);
                line-height: rem(30.57);
            }
        }

        p {
            font-weight: 400;
            font-size: rem(16);
            line-height: rem(25.33);
            text-align: center;
            color: $acdf-grey;
            margin-bottom: rem(0);
            margin-block-start: rem(0);
            margin-block-end: rem(0);
        
            @media (max-width: $tablet) {
                font-size: rem(15);
                line-height: rem(20.33);
            }
        }
    }

    &_img {

        &-line {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        
        &-flower {
            position: absolute;
            right: 0;
            top: -43.5%;
            z-index: 1;
        
            @media (max-width: rem(1376)) {
                display: none;
            }
        
            &-2{
                position: absolute;
                top: -29%;
                z-index: 1;
        
                @media (max-width: rem(1180)) {
                    display: none;
                }
            }
        }
    }

    &_left {
        position: relative;
        left: -7%;
            
        @media (max-width: $mobile) {
            left: rem(0);
        }
    }

    .col-sm-6 {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .container, .row, .col-sm-6 {
        max-width: none;
        margin: rem(0);
        padding: rem(0);
    }

    .row {
        position: relative;
        top: rem(107);
    }
}