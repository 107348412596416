// All padding spacing
.pad1  { padding: $pad-1;  }
.pad2  { padding: $pad-2;  }
.pad3  { padding: $pad-3;  }
.pad4  { padding: $pad-4;  }
.pad5  { padding: $pad-5;  }
.pad6  { padding: $pad-6;  }
.pad7  { padding: $pad-7;  }
.pad8  { padding: $pad-8;  }
.pad9  { padding: $pad-9;  }
.pad10 { padding: $pad-10; }
.pad11 { padding: $pad-11; }
.pad12 { padding: $pad-12; }
.pad13 { padding: $pad-13; }
.pad14 { padding: $pad-14; }
.pad15 { padding: $pad-15; }
.pad16 { padding: $pad-16; }
.pad17 { padding: $pad-17; }
.pad18 { padding: $pad-18; }
.pad19 { padding: $pad-19; }
.pad20 { padding: $pad-20; }
.pad21 { padding: $pad-21; }
.pad22 { padding: $pad-22; }
.pad23 { padding: $pad-23; }
.pad24 { padding: $pad-24; }
.pad25 { padding: $pad-25; }
.pad26 { padding: $pad-26; }
.pad27 { padding: $pad-27; }
.pad28 { padding: $pad-28; }
.pad29 { padding: $pad-29; }
.pad30 { padding: $pad-30; }