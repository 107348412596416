// All sections called "floating-phone"

.floating-phone {
    background-color: $acdf-low-gold;
    font-family: 'Great-Vibes' !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: fixed;
    bottom: rem(70);
    right: rem(20);
    padding: rem(0) rem(25);
    border-radius: rem(50);
    box-shadow: rem(0) rem(4) rem(19) rgba(0, 0, 0, 0.25);
    cursor: pointer;
    transition: all 0.4s ease;
    z-index: 25;

    &:hover {
        background-color: transparent;
        border: solid rem(3) $acdf-gold;
        animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        perspective: rem(1000);

        a {
            color: $acdf-gold;
        }
    }

    a {
        color: $acdf-white;
        font-size: rem(40);
        text-decoration: none;
        
        @media (max-width: rem(1376)) {
            font-size: rem(30);
        }

        &:hover {
            color: $acdf-gold;
        }
    }
}