// All sections called "six"

.six {

    &_home {
        position: relative;
        top: rem(-220);

        &_txt {
            padding: rem(60) rem(87) rem(0);
            height: 100%;
        
            @media (max-width: $tablet) {
                padding: rem(60) rem(87);
            }
        
            @media (max-width: $mobile) {
                padding: rem(60) rem(30);
            }
        }

        &_txt-2 {
            padding: rem(60) rem(32);
        }

        &_txt, &_txt-2 {
            background-color: $acdf-low-white;
            position: relative;
            h2, h3 {
                font-weight: 400;
                font-size: rem(40);
                line-height: rem(63.32);
                text-align: center;
                color: $acdf-blue;
                padding-bottom: rem(50);
        
                @media (max-width: $tablet) {
                    font-size: rem(22);
                    line-height: rem(30.57);
                }
            }

            span {
                display: inline-block;
                font-weight: 400;
                font-size: rem(20);
                line-height: rem(31.66);
                text-align: center;
                width: 100%;
                color: $acdf-gold;
        
                @media (max-width: $tablet) {
                    font-size: rem(15);
                    line-height: rem(25.66);
                }
            }

            p {
                font-weight: 400;
                font-size: rem(16);
                line-height: rem(25.33);
                text-align: center;
                color: $acdf-grey;
                margin-bottom: 0;
                margin-block-start: 0;
                margin-block-end: 0;
        
                @media (max-width: $tablet) {
                    font-size: rem(15);
                    line-height: rem(20.33);
                }
            }

            a {
                font-weight: 700;
                font-size: rem(20);
                line-height: rem(25.33);
                color: $acdf-grey;
                text-decoration: none;
                display: flex;
                justify-content: center;
                padding-top: rem(50);
                transition: 0.3s ease-out;
        
                @media (max-width: $tablet) {
                    font-size: rem(15);
                    line-height: rem(20.33);
                }

                &:hover {
                    transform: translateY(rem(-5));
                    color: $acdf-gold;
                }
            }
        }

        &_left {
            padding-left: rem(90);
            padding-right: rem(0);
        
            @media (max-width: $tablet) {
                padding-left: rem(0);
            }
        }

        &_right {
            padding-right: rem(90);
            padding-left: rem(0);
            justify-content: center;
        
            @media (max-width: $tablet) {
                padding-right: rem(0);
            }
        }

        &_img {
        
            @media (max-width: $tablet) {
                display: flex;
                justify-content: center;
                background-color: $acdf-low-white;
            }

            img {
                width: 100%;
                position: relative;
                height: 100%;
            }
            
            &-flower {
                position: absolute;
                right: 0;
                top: -12%;
                z-index: 1;
        
                @media (max-width: $tablet) {
                    display: none;
                }
            }
        }

        .col-sm-6 {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .col-sm-7, .col-sm-5 {
            
        
            @media (max-width: $tablet) {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }

        .container, .row, .col-sm-6 {
            max-width: none;
            margin: 0;
            padding: 0;
        }

        .row {
            padding-bottom: rem(160) !important;

            &:last-child {
        
                @media (max-width: $tablet) {
                    flex-direction: column-reverse;
                }
            }
        }
    }
}