// All sliders

.slider {
    &_pages {
        height: 70vh !important;
    }

    &_home,
    &_villa,
    &_appartement-f2-1er-etage,
    &_appartement-f3-1er-etage,
    &_appartement-f3-rdc,
    &_legnu-et-resina,
    &_proprete-et-entretien,
    &_decouvrez-la-region,
    &_pre-reservation,
    &_livre-d-or,
    &_message-envoye,
    &_erreur-404,
    &_visiter-notre-atelier,
    &_voir-nos-realisations,
    &_tenue-corse-octobre,
    &_corse-ete,
    &_plage-corse,
    &_corse-hiver,
    &_village-corse-du-sud,
    &_quelle-ville-visiter-corse,
    &_roadtrip-corse,
    &_que-faire-en-corse-avec-des-enfants,
    &_meilleure-excursion-bateau-corse,
    &_guide-voyage-corse,
    &_mauvais-oeil-en-corse,
    &_corse-en-van,
    &_voyager-avec-chien-en-corse {
        background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0)),
            url("/assets/img/includes/slider/home/bg.png");
        background-size: cover;
        background-repeat: no-repeat;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h1 {
            font-weight: 400;
            font-size: rem(51);
            line-height: rem(80.73);
            text-align: center;
            color: $white;

            @media (max-width: $small-mobile) {
                font-size: rem(25);
                line-height: rem(40);
            }

            &.h1_home {
                font-size: 43px;
                margin-top: 150px;
            }
        }

        h2, p.sub_h1 {
            font-weight: 400;
            font-size: rem(50);
            line-height: rem(54.4);
            text-align: center;
            color: $white;

            @media (max-width: $small-mobile) {
                font-size: rem(25);
                line-height: rem(30);
            }
        }

        p {
            font-weight: 400;
            font-size: rem(30);
            line-height: rem(54.4);
            text-align: center;
            color: $white;
        }
    }

    &_home,
    &_decouvrez-la-region {
        h2, p.sub_h1 {
            font-family: "Great-Vibes" !important;
        }
    }

    &_home {
        h2, p.sub_h1 {
            margin-top: 65px;
        }
    }

    &_message-envoye,
    &_erreur-404 {
        height: 100vh;

        h2 {
            padding-bottom: rem(50);
        }

        a {
            background-color: $acdf-gold;
            font-weight: 400;
            font-size: rem(20);
            line-height: rem(27);
            color: $acdf-low-white;
            display: flex;
            justify-content: center;
            padding: rem(10) rem(20);
            margin: rem(40) rem(494);
        }
    }

    &_villa {
        background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)),
            url("/assets/img/includes/slider/villa/bg.png");
    }

    &_appartement-f2-1er-etage {
        background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)),
            url("/assets/img/includes/slider/appartement-f2-1er-etage/bg.png");
    }

    &_appartement-f3-1er-etage {
        background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)),
            url("/assets/img/includes/slider/appartement-f3-1er-etage/bg.png");
    }

    &_appartement-f3-rdc {
        background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)),
            url("/assets/img/includes/slider/appartement-f3-rdc/bg.png");
    }

    &_legnu-et-resina {
        background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)),
            url("/assets/img/includes/slider/legnu-et-resina/bg2.png");
    }

    &_proprete-et-entretien {
        background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)),
            url("/assets/img/includes/slider/proprete-et-entretien/bg.png");
    }

    &_decouvrez-la-region {
        background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)),
            url("/assets/img/includes/slider/decouvrez-la-region/bg.png");
    }

    &_pre-reservation {
        background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)),
            url("/assets/img/includes/slider/pre-reservation/bg.png");
    }

    &_livre-d-or {
        background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)),
            url("/assets/img/includes/slider/livre-d-or/bg.png");
    }

    &_message-envoye {
        background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)),
            url("/assets/img/includes/slider/message-envoye/bg.png");
    }

    &_erreur-404 {
        background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)),
            url("/assets/img/includes/slider/erreur-404/bg.png");
    }

    &_visiter-notre-atelier {
        background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
            url("/assets/img/includes/slider/visiter-notre-atelier/visiter-notre-atelier.png");
    }

    &_voir-nos-realisations {
        background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)),
            url("/assets/img/includes/slider/voir-nos-realisations/bg.png");
    }

    &_tenue-corse-octobre {
        background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)),
            url("/assets/img/includes/slider/tenue-corse/bg.jpg");
    }

    &_corse-ete {
        background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)),
            url("/assets/img/includes/slider/corse-ete/bg.jpg");
    }

    &_plage-corse {
        background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)),
            url("/assets/img/includes/slider/plage-corse/bg.png");
    }

    &_corse-hiver {
        background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)),
            url("/assets/img/includes/slider/corse-hiver/bg.png");
    }

    &_village-corse-du-sud {
        background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)),
            url("/assets/img/includes/slider/village-corse-du-sud/bg.png");
    }

    &_quelle-ville-visiter-corse {
        background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)),
            url("/assets/img/includes/slider/quelle-ville-visiter-corse/bg.png");
    }

    &_roadtrip-corse {
        background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)),
            url("/assets/img/includes/slider/roadtrip-corse/bg.png");
    }

    &_que-faire-en-corse-avec-des-enfants {
        background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)),
        url("/assets/img/includes/slider/enfants-corse/bg.png");
    }

    &_meilleure-excursion-bateau-corse {
        background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)),
        url("/assets/img/includes/slider/excursion-corse/bg.png");
    }

    &_guide-voyage-corse {
        background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)),
        url("/assets/img/includes/slider/guide-voyage-corse/bg.png");
    }

    &_voyager-avec-chien-en-corse {
        background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)),
        url("/assets/img/includes/slider/voyager-avec-chien-en-corse/bg.png");
    }

    &_mauvais-oeil-en-corse {
        background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)),
        url("/assets/img/includes/slider/mauvais-oeil-en-corse/bg.png");
    }

    &_corse-en-van {
        background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)),
        url("/assets/img/includes/slider/corse-en-van/bg.png");
    }

    &_little {
        min-height: 50vh;
    }
}