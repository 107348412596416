// All styles for "stars"

.stars-small {
    @include star-template($smallStars, 1px, $smallStarScrollDuration)
}

.stars-medium {
    @include star-template($mediumStars, 2px, $mediumStarScrollDuration)
}

.stars-large {
    @include star-template($largeStars, 3px, $largeStarScrollDuration)
}

.shooting-stars {
    @include shooting-star-template($numShootingStars, 5px, 10s)
}


@keyframes animStar {
    from {
        transform: translateY(-#{$galaxyOffset}) translateX(-#{$galaxyOffset})
    }

    to {
        transform: translateY(-#{$galaxyBounds}px) translateX(-#{$galaxyBounds}px)
    }
}

@keyframes animShootingStar {
    from {
        transform: translateY(0px) translateX(0px) rotate(-45deg);
        opacity: 1;
        height: 5px;
    }

    to {
        transform: translateY(-#{$galaxyBounds}px) translateX(-#{$galaxyBounds}px) rotate(-45deg);
        opacity: 1;
        height: 800px;
    }
}

.stars-small {
    @include star-template($smallStars, 1px, $smallStarScrollDuration)
}

.stars-medium {
    @include star-template($mediumStars, 2px, $mediumStarScrollDuration)
}

.stars-large {
    @include star-template($largeStars, 3px, $largeStarScrollDuration)
}

.shooting-stars {
    @include shooting-star-template($numShootingStars, 5px, 10s)
}


@keyframes animStar {
    from {
        transform: translateY(-#{$galaxyOffset}) translateX(-#{$galaxyOffset})
    }

    to {
        transform: translateY(-#{$galaxyBounds}px) translateX(-#{$galaxyBounds}px)
    }
}

@keyframes animShootingStar {
    from {
        transform: translateY(0px) translateX(0px) rotate(-45deg);
        opacity: 1;
        height: 5px;
    }

    to {
        transform: translateY(-#{$galaxyBounds}px) translateX(-#{$galaxyBounds}px) rotate(-45deg);
        opacity: 1;
        height: 800px;
    }
}