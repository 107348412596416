.logement-card {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    outline: 2px solid #beae9d;

    @media(max-width: 998px) {
        flex-direction: column;
        padding-bottom: 10px;
    }

    .image-logement-wrapper {
        position: relative;
        width: 305px;
        height: 350px;

        @media(max-width: 998px) {
            width: 100%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .infos-logement {
        width: 50%;
    }
}