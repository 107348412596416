// All zoom

.one-zoom {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: rem(200);
    position: relative;
    top: rem(-150);
    
    @media (max-width: $tablet) {
        top: rem(-38);
    }
}

.zoom {
    cursor: url("/assets/img/includes/cursor/1.png"), auto !important;

    &-img {
        position: absolute;
        width: 100%;
        height: 100%;
        background-size: 100% 100%;
        background-position: center;
        transition: transform ease-in-out 300ms;

        img {
            display: none;
        }

        &-open {
            width: rem(953);
            height: rem(710);
            position: relative;
            overflow: hidden;
            transition: width ease 400ms, height ease 350ms, left cubic-bezier(0.4, 0, 0.2, 1) 420ms, right cubic-bezier(0.4, 0, 0.2, 1) 420ms;
        
            @media (max-width: $tablet) {
                display: flex;
                justify-content: center;
                width: rem(584);
                height: rem(438);
                z-index: 2;
            }
        
            @media (max-width: $small-mobile) {
                width: rem(350);
                height: rem(310);
            }

            &:hover .zoom-img {
                transform: scale(1.08);
                transition: transform cubic-bezier(0.4, 0, 0.2, 1) 450ms;
            }

            &.active {
                width: 100% !important;
                height: 100% !important;
                position: absolute;
            }

            &.postactive {
                position: absolute;
                z-index: 2;
                pointer-events: none;
            }

            &.active.positioned {
                left: 0 !important;
                top: 0 !important;
                transition-delay: 50ms;
            }
        }
    }
}