// All sections called "four"

.four {

    &_home {
        position: relative;
        top: rem(-500);

        &_txt {
            padding: rem(100) rem(154) rem(194);
        
            @media (max-width: rem(1240)) {
                padding: rem(0) rem(74);
            }
        
            @media (max-width: $tablet) {
                padding: rem(4) rem(154) rem(0);
            }
        
            @media (max-width: $small-mobile) {
                padding: rem(4) rem(35) rem(0);
            }

            h2, h3 {
                font-weight: 400;
                font-size: rem(25);
                line-height: rem(39.57);
                text-align: center;
                color: $acdf-blue;
                padding-bottom: rem(30);
        
                @media (max-width: $tablet) {
                    font-size: rem(22);
                    line-height: rem(30.57);
                    padding: rem(30) rem(0);
                }
            }

            p {
                font-weight: 400;
                font-size: rem(16);
                line-height: rem(25.33);
                text-align: center;
                color: $acdf-grey;
                margin-bottom: 0;
                margin-block-start: 0;
                margin-block-end: 0;
        
                @media (max-width: $tablet) {
                    font-size: rem(15);
                    line-height: rem(20.33);
                }
            }

            a {
                font-weight: 600;
                font-size: rem(20);
                line-height: rem(31.66);
                color: $acdf-grey;
                text-decoration: underline;
                display: flex;
                justify-content: center;
                padding-top: rem(50);
        
                @media (max-width: $tablet) {
                    font-size: rem(15);
                    line-height: rem(20.33);
                }
            }
        }

        &_left {
            position: relative;
            left: 4%;
        }

        &_img {

            img {
                width: 100%;
            }

            &-2 {
                display: flex;
                justify-content: center;
                position: relative;
                left: -3%;
                margin-top: rem(-515);
                padding-bottom: rem(190);
        
                @media (max-width: rem(1240)) {
                    display: none;
                }

                img {
                    border: rem(18) solid $acdf-white;
                }
            }

            &-3 {
                display: flex;
                justify-content: center;
        
                @media (max-width: rem(1240)) {
                    display: none;
                }

                img {
                    width: 90%;
                }
            }
        }

        .col-sm-6 {
            display: flex;
            align-items: center;
            justify-content: center;
        
            @media (max-width: $tablet) {
                flex: 0 0 100%;
            }
        }

        .container, .row, .col-sm-6 {
            max-width: none;
            margin: 0;
            padding: 0;
        }
    }

    &_home_2 {
        top: rem(-300);
    }

    &_villa {
        position: relative;
        top: rem(50);

        &_txt {
            display: flex;
            justify-content: center;

            a {
                background-color: $acdf-gold;
                font-weight: 400;
                font-size: rem(20);
                line-height: rem(27);
                color: $acdf-low-white;
                display: flex;
                justify-content: center;
                padding: rem(10) rem(20);
                margin: rem(40) rem(110);
                border: rem(2) solid $acdf-gold;
                transition: 0.3s ease-out;
        
                @media (max-width: $tablet) {
                    font-size: rem(15);
                    line-height: rem(20.33);
                }

                &:hover {
                    text-decoration: none;
                    background-color: transparent;
                    margin: rem(40) rem(60);
                    padding: rem(10) rem(40);
                    border: rem(2) solid $acdf-gold;
                    color: $acdf-gold;
                }
            }

            &-2 {
                background-color: $acdf-low-blue;
                padding: rem(42) rem(90) rem(122) rem(90);
                position: relative;
                left: -10%;
                z-index: 1;
            
                @media (max-width: $mobile) {
                    position: static;
                }

                h2 {
                    font-weight: 400;
                    font-size: rem(42);
                    line-height: rem(55.99);
                    color: $acdf-low-white;
        
                    @media (max-width: $mobile) {
                        font-size: rem(30);
                        line-height: rem(30.33);
                    }
                }

                ul {
                    padding-inline-start: rem(20);
                    
                    li {
                        font-weight: 400;
                        font-size: rem(15);
                        line-height: rem(25.5);
                        color: $acdf-low-white;
                        margin-bottom: 0;
                        margin-block-start: 0;
                        margin-block-end: 0;
                    }
                }
            }
        }

        &_img {
            display: flex;
            justify-content: flex-end;
            
            @media (max-width: $mobile) {
                padding-top: rem(50);
                justify-content: center;
            }

            img {
                width: 100%;
            
                @media (max-width: $mobile) {
                    width: 70%;
                }
            }
            
            &-flower {
                position: absolute;

                img {
                    position: relative;
                    width: 100%;
                    left: 166%;
                    top: rem(-404);
                    z-index: 2;
                }
            }

            &-divider {
                display: flex;
                justify-content: flex-start;
                position: relative;
                padding: rem(10) rem(60) rem(60) rem(0);
            }
        }

        &_right {
            position: relative;
            padding-right: rem(62) !important;
            top: rem(60);
            
            @media (max-width: $mobile) {
                position: static;
                padding-right: rem(0) !important;
            }
        }

        &_left {
            position: relative;
            padding-left: rem(138) !important;
            
            @media (max-width: $mobile) {
                padding-left: rem(0) !important;
            }
        }

        .container, .row, .col-sm-6 {
            max-width: none;
            margin: 0;
            padding: 0;
            align-items: center;
        }
    }
}