// Line separator

.line {
    &_img {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        top: rem(-220);
        
        img {  
            @media (max-width: $small-mobile) {
                opacity: 0;
            }
        }
    }
}