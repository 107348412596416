.one-reservation {
    position: relative;
    width: 100%;
    outline: 2px solid #beae9d;
    padding: 10px 20px;
    margin: 10px 0;
    display: flex;
    justify-content: space-around;
    align-items: center;

    @media(max-width: $tablet) {
        flex-direction: column;
    }
}